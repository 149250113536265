<template>
  <v-dialog
    max-width="600px"
    v-model="model"
  >
    <v-card>
      <v-toolbar
        flat
      >
        <v-toolbar-title>
          Настройки
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="px-4"
      >
        <template
          v-for="(field, index) in fields"
        >
          <v-text-field
            v-model="form[field.name]"
            :label="field.name"
            :disabled="!field.editable"
            :key="`field-${index}`"
          />
        </template>
      </v-card-text>
      <v-card-actions
        class="pa-4 d-flex justify-end"
      >
        <v-btn
          @click="model = false"
          text
        >
          Отмена
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$emit('success')"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  name: 'UserPreferencesDialog',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>